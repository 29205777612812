<section class="book-content">
    <div class="row">
        <div class="col-12">
            <h1>Adelina's Garden</h1>
        </div>
        <div class="col-md-3 col-xs-12">
			<img class="book-image" src="assets/adelinas_garden_cover_lg.jpg" alt="Cover of the book Adelina's Garden">
        </div>
        <div class="col-md-9 col-xs-12">
			<p>Sandris has no memory of his homeland and knows only the myths, legends and traditions of a Latvia that no longer exists. Growing up in Australia in the 1950s, he is caught between his grandmother's religious beliefs that are steeped in pagan spirituality and his widowed mother's unspiritual pragmatism.</p>
			<p>His search for identity takes him through rich layers of Australian life. In successive encounters that range from the violent to the sensual, he learns that the truth of reality comes from experience, not belief. </p>
			<p>To understand his father's apparent suicide, he must reach beyond his physical self. From childhood and adolescence, through to young manhood, he tests the limits of his physical existence. In near fatal experiences that encompass the extremes of fear and ecstasy, he reaches the interface of life and death.</p>
			<p>In this journey, he resolves the terrible truth about his father. His glimpse of the reality beyond life gains him an understanding of his world and who he is and where he belongs.</p>
			<h2>Details</h2>
			<ul>
				<li>ISBN 978-0-98068-191-8</li>
				<li>First edition, 2010</li>
				<li>2nd printing</li>
				<li>368 pages, A5 format, full colour glazed soft cover</li>
			</ul>
			<h2>Ordering</h2>
				<p><a routerLink="/contact" routerLinkActive="active">For all orders, please go to our contact page.</a></p>
			<h2>Free Sample Chapter</h2>
			<p><a href="assets/adelinasgarden_sample.pdf" target="_blank">Download a free sample from Adelina's Garden in PDF format.</a></p>
        </div>
    </div>
</section>