<section class="book-content">
    <div class="row">
        <div class="col-12">
            <h1>Castle of Light</h1>
        </div>
        <div class="col-md-3 col-xs-12">
			<img class="book-image" src="assets/castle_cover_lg.jpg" alt="Cover of the book Castle of Light">
        </div>
        <div class="col-md-9 col-xs-12">
			<p>Towards the end of the Second World War, Lieutenant Sandris Abele and some two hundred Latvian soldiers training in Prussia with the German Army are accused of fomenting an agenda that is not Nazi Germany's. They are disarmed and expelled from the German forces and ordered into the battle for Danzig.</p>    
			<p>Isolated in the Danzig killing ground, armed only with their battlefield trophy weapons, they await death or capture and execution by the Red Army. With no remaining options, they risk the unpredictable Baltic Sea in river ferries. After Bornholm's illusory safety, the majority continue on for Denmark but engine failure lands them in neutral Sweden, where they are interned. Soon after their arrival, the Swedish Cabinet concludes a secret trade agreement with Soviet Russia that involves thousands of German internees, with particular focus on the Baltic soldiers.</p>
			<p>The repercussions shake the foundations of Swedish society. As the drama unfolds, Sandris Abele is offered a chance for freedom, which, unknown to him, initiates the Soviet Union's deadly response to his wartime exploits.</p>
			<p>The novel starkly depicts the violent collision between humanity and politics. Coiled within this drama is Sweden's unique history that underlies the motivation to undertake an act abhorrent to every other western nation in post-war Europe.</p> 
			<h2>Details</h2>
			<ul>
				<li>ISBN 978-0-646-56795-2</li>
				<li>First edition, 2011</li>
				<li>Map, 238 pages, A5 format, black and white glazed soft cover</li>
			</ul>
			<h2>Ordering</h2>
			<p><a routerLink="/contact" routerLinkActive="active">For all orders, please go to our contact page.</a></p>
			<h2>Free Sample</h2>
			<p><a href="assets/castleoflight_sample.pdf" target="_blank">Download a free sample from Castle of Light in PDF format.</a></p>
        </div>
    </div>
</section>