<header class="header container">
    <div class="row">
        <div class="col-12">
            <nav class="navbar navbar-expand-lg navbar-light d-lg-none"> 
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto mobile-menu">
                    <li class="nav-item" ng-class="{active: isActive('home')}">
                        <a class="nav-link" routerLink="/" routerLinkActive="active">Home</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Books
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" routerLink="/books/path-of-the-moon" routerLinkActive="active">Path of the Moon</a>
                            <a class="dropdown-item" routerLink="/books/adalinas-garden" routerLinkActive="active">Adelina's Garden</a>
                            <a class="dropdown-item" routerLink="/books/castle-of-light" routerLinkActive="active">Castle of Light</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Biography
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" routerLink="biography/authors-biography" routerLinkActive="active">biography</a>
                            <a class="dropdown-item" routerLink="biography/authors-notes" routerLinkActive="active">Author's notes</a>
                        </div>
                    </li>
                    <li class="nav-item" ng-class="{active: isActive('contact')}">
                        <a class="nav-link" routerLink="/contact" routerLinkActive="active">Contact</a>
                    </li>
                </ul>
                </div>
            </nav>
        </div>
        <div class="col-12">
        <h1 class="main-title">OSKAR LAPA</h1>
        </div>
        <div class="col-12">
            <nav class="navbar navbar-expand-lg navbar-light d-none d-lg-block"> 
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto desktop-nav">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/" [class.active]="isActive == 'home'">Home</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [class.active]="isActive == 'books'">
                            Books
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" routerLink="books/path-of-the-moon">Path of the Moon</a>
                            <a class="dropdown-item" routerLink="books/adalinas-garden">Adelina's Garden</a>
                            <a class="dropdown-item" routerLink="books/castle-of-light">Castle of Light</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [class.active]="isActive == 'biography'">
                            Biography
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" routerLink="biography/authors-biography">biography</a>
                            <a class="dropdown-item" routerLink="biography/authors-notes">Author's notes</a>
                        </div>
                    </li>
                    <li class="nav-item" ng-class="{active: isActive('contact')}">
                        <a class="nav-link" routerLink="contact" [class.active]="isActive == 'contact'">Contact</a>
                    </li>
                </ul>
                </div>
            </nav>
        </div>
    </div>
</header>

<div class="container">
    <router-outlet (activate)="onActivate()"></router-outlet>
</div>

<footer>
    <a routerLink="/">home</a> | 
    <a routerLink="/books/path-of-the-moon">Path of the Moon</a> | 
    <a routerLink="/books/adalinas-garden">Adelina's Garden</a> | 
    <a routerLink="/books/castle-of-light">Castle of Light</a><br>
    <a routerLink="biography/authors-biography">biography</a> | 
    <a routerLink="biography/authors-notes">Author's notes</a> | 
    <a routerLink="contact">Contact</a>
</footer>