import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PathOfTheMoon } from './path-of-the-moon.component'
import { AdalinasGarden } from './adalinas-garden.component';
import { AuthorsNotes } from './authors-notes.component';
import { Biography } from './biography.component';
import { CastleOfLight } from './castle-of-light.component';
import { Contact } from './contact.component';
import { Main } from './main.component';

const routes: Routes = [
  { path: '', component: Main },
  { path: 'books/adalinas-garden', component: AdalinasGarden },
  { path: 'books/path-of-the-moon', component: PathOfTheMoon },
  { path: 'books/castle-of-light', component: CastleOfLight },
  { path: 'biography/authors-notes', component: AuthorsNotes },
  { path: 'biography/authors-biography', component: Biography },
  { path: 'contact', component: Contact },
  { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
