import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component'
import { AuthorsNotes } from './authors-notes.component';
import { Biography } from './biography.component';
import { PathOfTheMoon } from './path-of-the-moon.component'
import { CastleOfLight } from './castle-of-light.component';
import { AdalinasGarden } from './adalinas-garden.component';

import { Contact } from './contact.component';
import { Main } from './main.component';

import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [
    AppComponent,
    AdalinasGarden,
    AuthorsNotes,
    Biography,
    CastleOfLight,
    PathOfTheMoon,
    Contact,
    Main
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
