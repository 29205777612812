import { Component } from '@angular/core';
import { Router } from '@angular/router';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(private router: Router){}

  public href: string = "";

  public isActive: string = "";

  ngOnInit() {
    this.setActive('home');
  }
  
  public setActive(viewLocation: string) { 
    this.isActive = viewLocation;  
  }

  public onActivate() {
    window.scroll(0,0);

    console.log(this.router.url);

    // make array
    let locationArr = this.router.url.split('/');
    console.log(locationArr);
    let activePage = (locationArr[1] == '') ? 'home' : locationArr[1];

    this.isActive = activePage;
    console.log("this.isActive ",activePage)
  }
}