<section class="book-content">
    <div class="row">
        <div class="col-12">
            <h1>Biography</h1>
        </div>
        <div class="col-md-3 col-xs-12">
			<img class="book-image" src="assets/author-portrait.jpg" alt="Portrait of the Author Oskar Lapa">
        </div>
        <div class="col-md-9 col-xs-12">
			<p>Oskar Lapa's family migrated to Australia in 1951. Ten years later, at Sydney University, he played billiards and briefly, cricket, won a University Tiddlywinks Pink and intermittently published in the <span class="italics">Honi Soit</span>.</p> 

		   <p>He left archaeology honours to work as a Customs officer, then as a building demolisher, followed by various other jobs around the country before ending his tour of Australian industries as the manager of a Northern Territory iron mine's industrial store.</p>

		   <p>Returning to his studies and his writing, he taught in an inner city public school and then in the north-west of Sydney, where he lives in the foothills of the Blue Mountains with his family.</p>

		   <p>Cricket was his main sport. He was a strike bowler with Sydney Southern Districts - now known as Fairfield-Liverpool Grade Cricket Club - and is pleased to note that the older he gets, the faster he used to bowl. </p>

		   <p>He reads, plays chess (impulsively), writes first drafts of a novel with a fountain pen, walks the dog, enjoys blues rock and baroque, gardens – sort of, digs stormwater drains as needed, makes cabinets in the work shed, splits firewood, mends fences and takes an interest in local affairs.</p>
        </div>
    </div>
</section>