<section class="book-content">
    <div class="row">
        <div class="col-12">
            <h1>The Path of the Moon</h1>
        </div>
        <div class="col-md-3 col-xs-12">
            <img src="assets/pathcover.png" class="book-image" alt="Cover of the book Path of the moon">
        </div>
        <div class="col-md-9 col-xs-12">
            <p>In the dark days before the Second World War, the Soviet invasion has brought terror to Katrina's little country of Latvia. The mass deportations to Siberia cease when Nazi Germany attacks Soviet Russia. Within a week, the Germans are in Riga feted as saviours, an illusion that fades rapidly when they enforce Nazi policies and arrest and murder Jewish citizens and other condemned minorities.</p> 

            <p>Katrina's husband Sandris understands Germany's historical agenda for his nation but he and thousands like him have their own agenda. They rush to regroup Latvia's army and ‘ride east with the devil' to rescue their deported relatives. Sandris plunges into the savage fighting, leaving Katrina to manage both the family and the family business.</p>

            <p>Young and uncertain of her abilities, Katrina copes with her responsibilities by desperately blocking out the Nazis' relentless barbarism. The horror of the Holocaust escalates and an overwhelming awareness impels her to take a perilous step towards self-reliance.</p>

            <p>By the summer of 1944, the Red Army has advanced into Latvia and Katrina finds herself torn between her children's safety and her husband's reassurances. Her family's fate depends on her and, as the torrent of war sweeps Sandris to his destiny, she makes her ultimate decision.</p>
            <h2>Details</h2>
            <ul>
                <li>ISBN 978-0-646-94488-3</li>
                <li>New Edition, 2016</li>
                <li>332 pages</li>
                <li>440 pages</li>
                <li>A5 format</li>
                <li>Full colour glazed soft cover</li>
            </ul>
            <h2>Ordering</h2>
			<p><a routerLink="/contact" routerLinkActive="active">For all orders, please go to our contact page.</a></p>
            <h2>Free Sample</h2>
            <p><a href="assets/thepathofthemoon_sample.pdf" target="_blank">Download a free sample from The Path of The Moon in PDF format.</a></p>
        </div>
    </div>
</section>