<section class="main-content">
  
  <!-- PATH CONTENT -->

  <div class="row main-book-details">
    <div class="col-12">
      <h1>The Path of the Moon</h1>
      <h2>A Novel of the Eastern Front</h2>
      <h3>New Edition</h3>
    </div>
    <div class="col-md-3 col-xs-12">
      <img src="assets/pathcover.png" class="book-image" alt="Cover of the book Path of the moon">
    </div>
    <div class="col-md-9 col-xs-12">
        <p class="italics">This powerful novel opens up a view of the Second World War rarely seen in western literature.</p>
        <p>A tiny independent nation is forced to choose between two savage invaders – Soviet Russia and Nazi Germany. A mere twenty years earlier the fledgling Latvian Army drove out Lenin's Red hordes and the mercenary German occupation force. Tragically, Latvia again becomes their battlefield.</p>
        <p>Through the lives of one family, this novel, closely based on actual events, captures the distress and horror that the war inflicts on citizens and combatants alike.</p>
        <p><a routerLink="/books/path-of-the-moon" class="bold">read more...</a></p>
    </div>
  </div>
  <hr>

  <!-- ADALINAS CONTENT -->
  <div class="row main-book-details">
    <div class="col-12">
      <h1>Castle of Light</h1>
    </div>
    <div class="col-md-3 col-xs-12">
      <img src="assets/castle_cover_sml.jpg" class="book-image" alt="Cover of the book Castle of Light">
    </div>
    <div class="col-md-9 col-xs-12">
      <p class="italics">Trading hostages for treasure or political advantage is an inhumane custom ostensibly fallen into disrepute. Yet overwhelming fear and greed can drive modern nations into primitive brutality.</p>
      <p>The cruel drama that was played out in Sweden in the immediate aftermath of the Second World War is virtually unknown beyond the nations involved.</p>
      <p>For many, it will always be a tragedy suffered by betrayed and martyred heroes; for others, it is emblematic of judicial victory over dangerous traitors and criminals. There are those, too, for whom the depth of national shame is such that they wish this violent episode be expunged from history and forgotten.</p>
      <p>However one views the incident, it remains a dark mirror to the prevailing treatment of asylum seekers.</p>
      <p><a routerLink="/books/castle-of-light" class="bold">read more...</a></p>
    </div>
  </div>
  <hr>
  <!-- CASTLE CONTENT -->

  <div class="row main-book-details">
    <div class="col-12">
      <h1>Adelina's Garden</h1>
    </div>
    <div class="col-md-3 col-xs-12">
      <img src="assets/adelinas_garden_cover_sml.jpg" class="book-image" alt="Cover of the Adelina's Garden">
    </div>
    <div class="col-md-9 col-xs-12">
      <p>Beyond the interface of life and death lies the unknown that generates humanity's greatest terror and most profound ecstasy. All humanity passes through that interface, yet few attempt to resolve the mystery of our destination.</p>   
      <p>Quests into that reality are impelled by a rare confluence of events and personal qualities. Rarer still are those who gain a glimpse of the unknown, and then return.</p>
      <p>This book is the story of such a journey.</p>
      <p><a routerLink="/books/adalinas-garden" class="bold">read more...</a></p>
    </div>
  </div>

</section>