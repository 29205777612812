<section class="book-content">
	<div class="row">
		<div class="col-12">
			<h1>Contact</h1>
		</div>
		<div class="col-12">		
			<h2>Books by Oskar Lapa, author and publisher</h2>
		</div>
		<div class="col-lg-4">
			<b>The Path of the Moon</b><br> 
			New Edition<br>
			ISBN 978 0 646 944883<br>
			Published 2016<br>
			Extent: 440 pages<br>
		</div>
		<div class="col-lg-4">
			<b>Adelina's Garden</b><br>
			ISBN 978 0 98068 191 8<br>
			Published: 2010<br>
			Extent: 367 pages<br>
		</div>
		<div class="col-lg-4">
			<b>Castle of Light</b><br>
			ISBN 978 0 646 56795 2<br>
			Published: 2011<br>
			Extent: 233 pages<br>
		</div>
	    <div class="col-12">		
			<br>
			<h2>Available from:</h2>
			<div class="seller-details">
				<span class="bold">Sydney Latvian Society</span>, SLB Bookshop<br>
				PO Box 457, Strathfield NSW 2135<br>
				Phone: (02) 9744 8500<br>
				Email:  <a href="mailto:slb_riga@bigpond.com">slb_riga@bigpond.com</a>
			</div>
			<div class="seller-details">
				<span class="bold">Oskar Lapa</span><br>
				Address: PO Box 381, Kurrajong NSW 2758 AUSTRALIA<br>
				The Path of the Moon, New Edition $25, $35 incl. post &amp; packing within Australia<br>
				Adelina's Garden - $25,  $35 incl. post &amp; packing within Australia<br>    
				Castle of Light - $25,  $35 incl. post &amp; packing within Australia<br>
				NOTE: Overseas orders must be in $Aust. Please enquire via email to <a href="mailto:sales@oskarlapa.com">sales@oskarlapa.com</a>
			</div>
			<div class="seller-details">
				<span class="bold">Australian Online Bookshop</span><br>
				Address: 8 Devon Road, Bassendean WA 6054<br>
				Phone: (08) 9377 70991<br>  
				Web: <a href="http://www.bookworm.com.au/">www.bookworm.com.au</a>
			</div>
			<div class="seller-details">
				<span class="bold">Museum of Occupation of Latvia</span><br>
				Address: Raiņa Bulvāris 7 Rīga LV-1050 LATVIA<br>
				Phone: (08) 9377 70991
			</div>
		</div>
	</div>
</section>