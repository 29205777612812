<section class="book-content">
	<div class="row">
		<div class="col-12">
			<h1>Author's notes</h1>
		</div>
		<div class="col-12">
			<p>Some background to the books </p>

			<h3>The Path of the Moon, New Edition</h3>

			<p>On August 21st, 1991, Latvia became an independent nation with a burdensome legacy left by the Soviet and Nazi occupations. 
			The first decade of restoring this small nation was complex and arduous, governed by pragmatism where sorting through Soviet and Nazi archives was not a high priority. </p>
			
			<p>Consequently, documents relevant to my research for the original book were slow to see the light of day and as a result, accurate detail was scarce for the first edition of The Path of the Moon. It lacked the darker side of the full story of Latvia in that period of occupation and was published in 1995 as an impressionistic novel. </p>
			
			<p>Nevertheless, the book's energy and guarded optimism echoed the sentiments of Latvian refugees, who had had nothing but sad news about their homeland until 1991. </p>
			
			<p>In December, 1995, the first edition was reviewed in the Australian Latvian News by the noted author and literary critic, Eduards Silkalns, who wrote: "For decades we have delighted in the thought that the Latvian nation and our fate would become known world-wide, if someone managed to write about it on a sufficiently large scale, so that it is easily readable, as well as being a novel of literary value. When one appears written in one of the great national languages...we enter it with a question: is this finally the long-awaited major work, or will we have to await another?"</p>
			
			<p>I trust that this 2016 edition of The Path of the Moon is a satisfactory response.</p>

			<h3>Adelina's Garden</h3>

			<p>The first drafts of Adelina's Garden were written in Bath, England, where I lived for several years. 
			In 2004 a close friend, the landscape artist Terry Hayes, in our correspondence mentioned that he'd be interested to see my description of the garden that Adelina nurtured. Within a month I received a splendid little oil painting that captured the nuances of Adelina in Australia and which became the book's cover. </p>

			<p>By mid-March, 2007, back in Australia, I was deep into the book's final form. When the unlisted phone rang, I picked up, my mind elsewhere.<br>
			"Yep, hello," I muttered.<br>
			"Gough here." <br>
			"Sorry, who?"<br>
			"Gough...Gough Whitlam."<br>
			Stunned silence. I impulsively had sent the former Prime Minister a couple of pages featuring a late-fifties fictitious politician who bore a remarkable likeness to him. It incorporated a word play riposte to his 1975 outburst about '...Vietnamese Balts.' I panicked, as one does.  <br>   
			"Oh...good god!"<br>
			"Close."<br>
			"How...ah...did you get my number?"<br>
			"Power." <br>
			"Ah," I breathed.<br>
			"I enjoyed your writing. Interesting, very funny. However, your Juvenal quote is not in the Latin your character would have used."
			"It's from Bloomsbury, I checked with..."<br>
			"They're all wrong. It should be..."<br>
			The elegant tutorial that followed so captured me that I heard his voice but retained virtually nothing of the tutorial's content. I thanked him and, terrified of further discussion, got a laugh when I said that I felt like the Jewish graffitist given a Latin lesson by John Cleese's Roman centurion.<br>
			I was on firmer ground on education, health, recognition of small nations, and the Westminster system, accomplished by him with clarity, wit and not a single acrimonious word about anyone. <br>
			He was a man at peace with himself.</p>

			<p>Several clean copies later, fussing over commas and hyphens, I realized I had to get the manuscript out of my reach. I sent it to a literary competition. In time a letter arrived. From over 400 entries, Adelina's Garden had been unanimously shortlisted for the 2008 Australian Broadcasting Commission Fiction Award. Ultimately, the participating publisher chose another novel, but it gave me a nice boost.</p>
			
			<p>The ABC Fiction Award ended in 2009, which is a great pity. It gave many Australian writers recognition in having their work short-listed with a chance of commercial publication. It is an incalculable loss to Australian literature.</p>

			<h3>Castle of Light</h3>

			<p>This novel, chronologically, is the second book in The Latvian Trilogy. I returned to my drafts of it with a sense of having missed a crucial reference, a connection to the present world.</p>
			
			<p>In April 2009, while scanning the Sydney Morning Herald, an article stopped me cold: eleven Afghan asylum seekers had been forcibly deported from Nauru Detention Centre back to Afghanistan, where they were killed by the Taliban. 
			It was all there.</p>
			
			<p>Desperate people escaping extreme danger in unsafe boats, landing in presumed safety, held in barbed wire compounds, demonized by the media, endlessly delayed personal checks, deliberately given no hope until repatriated by force to suffering and death. All this layered with politicians' pious mendacity, calculated evasions, denials of responsibility, professed ignorance of deadly danger to their victims and the savagery of their legalized injustice.</p>
			
			<p>The connection is glaringly obvious. </p>
			
			<p>Some sixty-three years after democratic Sweden chose to forcibly repatriate asylum seekers into life-threatening danger, democratic Australia, despite being a signatory to major international human rights treaties, is enforcing shamefully similar policies.</p>  
		</div>
	</div>
</section>